/* eslint-disable max-len */
import React from 'react';
import BasePage from 'pages/BasePage';
import { Link } from 'react-router';
import { ROUTES } from 'constants';
import Banner from 'components/Banner';
import HowItWorks from 'components/HowItWorks';
import Benefits from 'components/Benefits';
import DeduplicationChecksContent from './components/DeduplicationChecksContent';
import {
  TITLE,
  HOWITWORKS,
  TITLE2,
  CONTENT,
} from './constants';
import help from 'models/help';

export default class DeduplicationChecks extends BasePage {
  className = 'ts-DeduplicationChecks';
  static title = 'Deduplication & Greylist Checks';
  static description =
    'Prevent duplicate identities, detect repeat attackers and synthetic identities, ensure compliance, and set your own adjudication thresholds with Trust Stamp.';

  componentDidMount() {
    help.set({ showDemoButton: true });
  }

  componentWillUnmount() {
    help.set({ showDemoButton: false });
    super.componentWillUnmount();
  }

  render() {
    return (
      <div className={ this.rootcn() }>
        <Banner theme="avatars" size="small">
          <h1>Deduplication & Greylist Checks</h1>
        </Banner>

        <DeduplicationChecksContent />

        <HowItWorks
          content={ HOWITWORKS }
          sectionTitle={ TITLE }
          subTitle="BENEFITS"
          columnCount={ 4 }
        />

        <Benefits sectionTitle={ TITLE2 } content={ CONTENT } subTitle="FEATURES" />

        <section>
          <div className="ts-container">
            <h6>GET IN TOUCH</h6>
            <h2>Eliminate Repeat Fraud. Protect Your Platform.</h2>
            Don’t let repeat bad actors evade detection. Take control of your
            identity verification process today. Contact Trust Stamp to learn
            how our Deduplication and Greylist checks can enhance your fraud
            prevention strategy and safeguard your business.
            <br />
            <br />
            <Link to={ ROUTES.SCHEDULE_DEMO } className="ts-button--primary">
              Book Demo
            </Link>
          </div>
        </section>
      </div>
    );
  }
}
